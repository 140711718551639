import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2022-stories/more-stories"
import Vimeo from '@u-wave/react-vimeo';

import Hero from '../../images/fall-winter-2022/new-wave-of-future-scientists/hero.jpg';
import socialBanner from "../../images/fall-winter-2022/new-wave-of-future-scientists/hero.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'A New Wave of Future Scientists';
    var pageDesc = 'In the decades to come, the Nicholas and Lee Begovich Center for Gravitational-Wave Physics and Astronomy (GWPAC) will continue to provide world-class, transformational and high-tech research opportunities to help students achieve their career goals.';
    var slug = 'new-wave-of-future-scientists';

    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${Hero})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      color: 'white',
      height: '85vh'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2022">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`fall-winter-2022 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
            <span className="animated fadeInDown delay-1s byline">By Debra Cano Ramos</span>
          </div>
          <div className="tint"></div>
        </div>
        <article className="wrap small">

          <p>In the decades to come, the Nicholas and Lee Begovich Center for Gravitational-Wave Physics and Astronomy (GWPAC) will continue to provide world-class, transformational and high-tech research opportunities to help students achieve their career goals.</p>

          <p>Currently, 20 undergraduate and graduate students are working on a range of research projects, which includes investigating the science capabilities of Cosmic Explorer detector designs.</p>

          <p>The research mentors will continue to empower future generations in designing, developing and building the new observatories, which are expected to be operating in their early career stages. </p>

          <p>“We meet every week to discuss projects that range from instrumental development, detector design, computation, data science, relativistic theory and astronomical interpretation,” says Jocelyn Read, associate professor of physics.</p>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <figure className="align-wide">
            <Vimeo
              video="737650186"
              autoplay={false}
              responsive={true}
              showPortrait={false}
              showTitle={false}
              showByline={false}
            />
            <figcaption>Alumna Denyz Melchor's GWPAC story</figcaption>
          </figure>
          </ScrollAnimation>

          <p>Since GWPAC opened in 2012, of the center’s <a href="https://news.fullerton.edu/2022/10/a-decade-of-gravitational-wave-student-success/" target="_blank" rel="noreferrer">51 alumni</a> — of which 44% are women — half are pursuing or have completed doctoral programs related to gravitational-wave research at institutions such as Caltech, MIT, Syracuse University, Northwestern University, Louisiana State University and UCLA. Other graduates are becoming leaders in industry at NASA, Apple and The Aerospace Corporation.</p>

          <p>“The thing I’m most proud of is what our students have done,” says Geoffrey Lovelace, professor of physics, of the center’s decade of accomplishments. “Students have made so many meaningful contributions to this research very early in their careers. After graduating, they have achieved success in graduate school and in industry.”</p>

          <p>For their groundbreaking faculty-student research, over the last decade the Cal State Fullerton team has garnered about $8 million in federal grant funding, including from the National Science Foundation. </p>

          <p>The most recent NSF awards total nearly $1.8 million, with students getting hands-on, immersive experiences through these projects. The grants include:</p>
          <ul>
            <li><a href="https://news.fullerton.edu/2022/08/1-2-million-grant-to-prepare-underrepresented-students-for-doctoral-programs-in-gravitational-wave-astronomy/" target="_blank" rel="nofollow noreferrer">$1.2 million to continue preparing underrepresented students in gravitational-wave astronomy careers</a>, with partner institutions Syracuse University, Northwestern University and Washington State University;</li>
            <li>$355,683 to support optics experiments in the lab to increase the astrophysical range of LIGO and Cosmic Explorer; and</li>
            <li>$225,832 award for GWPAC’s work using supercomputers to model the binary black holes whose gravitational waves Cosmic Explorer will observe.</li>
          </ul>
            
          <p>“Gravitational-wave astronomy is a rapidly growing field. We’re introducing students to computational, optical and data science skills, and they’re working with real astronomical data and instruments to understand the universe,” Read says. “They’re building key skills for careers in science and technology.” <span className="end-mark" /></p>
 

        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 